<template>
  <div>

    <!-- Main Nav -->
    <div class="top_menu row m-0" id="top">
      <div class="align-items-center w-100 d-flex header_margin justify-content-between">
        <router-link to="/" class=" header-contact">
          <img alt="#" class="logo" src="../src/assets/img/web/logo.png">
        </router-link>
        <div class="header-contact right-container">
          <ul class="list  d-md-flex d-sm-none m-0">
            <li>
              <a>
                <img alt="#" src="../src/assets/img/web/locate.png">
                Mannuthy, Thrissur, Kerala
              </a>
            </li>
            <li>
              <a class="dn_btn" href="mailto:info@xeoscript.com" target="_blank">
                <img alt="#" src="../src/assets/img/web/mail.png">
                info@xeoscript.com
              </a>
            </li>
            <li>
              <a href="tel:+917994000666">
                <img alt="#" src="../src/assets/img/web/call.png">
                +91 7994 000 666
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="list  header_social ">
      <ul class=" d-none d-md-flex  m-0 justify-content-end">
        <li>
          <a href="https://www.facebook.com/xeoscript" target="_blank">
            <img alt="#" src="../src/assets/img/web/socialmedia/fb.png">
          </a>
        </li>
        <li>
          <a href=" https://www.instagram.com/xeoscript/" target="_blank">
            <img alt="#" src="../src/assets/img/web/socialmedia/insta.png">
          </a>
        </li>
        <li class="d-none">
          <a href="#">
            <img alt="#" src="../src/assets/img/web/socialmedia/twitt.png">
          </a>
        </li>
        <li class="d-none">
          <a href="#">
            <img alt="#" src="../src/assets/img/web/socialmedia/pin.png">
          </a>
        </li>
        <li class="d-none">
          <a href="">
            <img alt="#" src="../src/assets/img/web/socialmedia/youtb.png">
          </a>
        </li>
        <li class="d-none">
          <a href="">
            <img alt="#" src="../src/assets/img/web/socialmedia/behans.png">
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/xeoscript-technologies/" target="_blank">
            <img alt="#" src="../src/assets/img/web/socialmedia/linked.png">
          </a>
        </li>
      </ul>
      <ul class="list  d-md-none m-0">
        <li>
          <a href="">
            <img alt="#" src="../src/assets/img/mobile/Locate_mob.png">
            Mannuthy, Thrissur, Kerala
          </a>
        </li>

        <li>
          <a class="dn_btn" href="mailto:info@xeoscript.com">
            <img alt="#" src="../src/assets/img/mobile/MAIL_mob.png">
            info@xeoscript.com
          </a>
        </li>
        <li>
          <a href="tel:+917994000666">
            <img alt="#" src="../src/assets/img/web/footer/call.png">
            +91 7994 000 666
          </a>
        </li>
      </ul>
    </div>

    <!-- mainbanner -->

    <div class="align-items-center d-lg-flex justify-content-center mainbanner ">
      <div class="banner-slider-container">
        <swiper class="swiper home-slider" :options="swiperOption1">
          <swiper-slide>
            <div class="banner-slide">
              <img src="../src/assets/img/web/mainbanner/bgi.jpg" class="img-fluid d-none d-lg-block" alt="#">
              <img src="../src/assets/img/mobile/mainbanner/bgi.jpg" class="img-fluid d-lg-none" alt="#">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="banner-slide">
              <img src="../src/assets/img/web/mainbanner/bgi.jpg" class="img-fluid d-none d-lg-block" alt="#">
              <img src="../src/assets/img/mobile/mainbanner/bgi.jpg" class="img-fluid d-lg-none" alt="#">
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="banner-slide">
              <img src="../src/assets/img/web/mainbanner/bgi.jpg" class="img-fluid d-none d-lg-block" alt="#">
              <img src="../src/assets/img/mobile/mainbanner/bgi.jpg" class="img-fluid d-lg-none" alt="#">
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="mainbanner-container1 text-center text-md-left">
        <img class="d-none d-md-block" alt="#" src="../src/assets/img/web/mainbanner/icon1.png">
        <h3 class="mt-3 mainbanner-heading">Web & Mobile App<br> Development Company</h3>
        <p class="mainbanner-text mt-md-5">
          <b class="border-top-custom"># With</b> the introduction of smart phone and
          other devices the world is in our hands
        </p>
        <a href="tel:+917994000666" class="btn btn-primary mt-3 xeo-btn">
          <img alt="#" src="../src/assets/img/phone-call.png">
          CALL US
        </a>
        <a href="https://wa.me/917994000666" target="_blank" class="btn btn-success ml-3 ml-lg-1 ml-xl-3 mt-3 xeo-btn">
          <img alt="#" src="../src/assets/img/whatsapp.png">
          WHATSAPP
        </a>
      </div>

      <div class="mainbanner-container2 text-center">
        <h5>Enquire Now</h5>

        <transition name="fade" mode="out-in">

          <div class="text-center" v-if="state === 'S'" key="'S">
            <p class="text-success bg-white">The mail has been successfully submitted.</p>
          </div>

          <div class="text-center" v-else-if="state === 'F'" key="'F">
            <p class="text-danger bg-white">The mail delivery has been failed.</p>
          </div>

          <div class="alert alert-info mb-0 w-100 text-center" v-else-if="state === 'L'" key="'L">
            <div class="">
              <!--              <loading-animation/>-->
            </div>
            <p class="mb-0">Please wait while communicate with the server</p>
          </div>

          <form class="mt-2 banner-form" v-else @submit.prevent="onSubmit" name="FC" autocomplete="off">
            <div class="form-group">
              <input type="text" class="form-control" placeholder=" " v-on:keypress="isLetter($event)"
                     v-model="name" id="name" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Your Name *</label>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" :class="['input-group', isEmailValid()]" placeholder=" "
                     v-model="email" id="email" name="email" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Email *</label>
            </div>
            <div class="form-group">
              <input class="form-control" placeholder=" " v-model="phone" id="phone" required
                     maxlength="10" @keypress="onlyNumberKey($event)" type="tel" pattern="[0-9]*">
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Phone *</label>
            </div>
            <div class="clearfix"></div>
            <div class="row text-left">
              <div class="col-12">
                <label>You're looking for ? *</label>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option pr-lg-3 pr-xl-0 pr-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Web Application
                    <input type="checkbox" checked="checked" id="inlineCheckbox1" v-model="checkedService" name="c1"
                           value="Web Application">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option px-lg-3 p-xl-0 p-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Mobile Application
                    <input type="checkbox" checked="checked" id="inlineCheckbox2" v-model="checkedService" name="c2"
                           value="Mobile Application">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option pr-lg-3 pr-xl-0 pr-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Website Development
                    <input type="checkbox" checked="checked" id="inlineCheckbox3" v-model="checkedService" name="c3"
                           value="Website Development">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option px-lg-3 p-xl-0 p-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Software Development
                    <input type="checkbox" checked="checked" id="inlineCheckbox4" v-model="checkedService" name="c4"
                           value="Software Development">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option pr-lg-3 pr-xl-0 pr-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Digital Marketing
                    <input type="checkbox" checked="checked" id="inlineCheckbox5" v-model="checkedService" name="c5"
                           value="Digital Marketing">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 col-xl-6 mb-1 services-option px-lg-3 p-xl-0 p-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">E commerce Development
                    <input type="checkbox" checked="checked" id="inlineCheckbox6" v-model="checkedService" name="c6"
                           value="E commerce Development">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-lg-12 mb-1 services-option pr-lg-3 pr-xl-0 pr-md-0">
                <div class="form-check form-check-inline">
                  <label class="container1">Online Reputation Management
                    <input type="checkbox" checked="checked" id="inlineCheckbox7" v-model="checkedService" name="c7"
                           value="Online Reputation Management">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <textarea placeholder=" " rows="1" class="form-control" v-model="message" required> </textarea>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Tell us about your project</label>
            </div>
            <button type="submit" class="btn btn-block btn-primary mt-3">Get A Quote</button>
          </form>

        </transition>

      </div>
    </div>

    <!-- service section-->

    <div class="mt-4 mb-4 service-section">
      <div class="pt-4 pb-4 header_margin">
        <div class="text-center">
          <h4 class="mt-2 choose-us-header">Our Services</h4>
          <hr>
          <div class="row m-0">
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Mobile App <br> development</h4>
                  <hr>
                  <p class="card-text"><b>Are you in search of futuristic yet pocket-friendly mobile app solutions for
                    your
                    business? Look no further and reach out to us for the best mobile applications.</b></p>
                  <p class="card-text text-justify">The Advantages of high-performing mobile applications for your business are
                    plenty. Why do we say that? Well, a myriad of reasons, but chiefly because a great mobile app will
                    resonate your business and enhance your operation, Promote your business ideas and services with
                    your customer and enhance awareness.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Digital Marketing Services</h4>
                  <hr>
                  <p class="card-text"><b>By proper use of digital marketing technologies, businesses can upgrade their
                    brand performances easily. They can present relevant information and attract the right clients to
                    choose their products or services.</b></p>
                  <p class="card-text text-justify">Being fast and result-oriented, digital marketing is gaining popularity for
                    working effectively in any industry type. Many reasons contribute to our success. We became the
                    best-reviewed digital marketing company, mostly due to our passion and a will to go that extra mile
                    for our clients.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Software Application Development</h4>
                  <hr>
                  <p class="card-text"><b>The process wherein a set of tasks or programs are created or developed to
                    promote and accelerate your business growth.</b></p>
                  <p class="card-text text-justify">Our developers can produce next-generation app development so that it simplifies
                    business complexities and takes your business to the lead. Our software solutions generate
                    disruptions within organizations and boost business productivity</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Web Application Development Services</h4>
                  <hr>
                  <p class="card-text"><b>Web application is the new phenomenon that is rocking the World Wide Web in
                    leaps and bounds. Having a web application in place for your business is definitely going to
                    transform your business and take it to a new and better level</b></p>
                  <p class="card-text text-justify">All the web applications that we have developed have been portraying extremely
                    outstanding performance both business to business as well as to business to consumer level making
                    sure that all the business challenges are met.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Web Design and Development Services</h4>
                  <hr>
                  <p class="card-text"><b>Our dedicated team of web designers and developers are fully determined to
                    provide you with a solution to the best of our abilities.</b></p>
                  <p class="card-text text-justify">When it comes to web designing and development services, we are the one-stop
                    solution as we focus to deliver a conversion-oriented, customer-centric, and user-friendly interface
                    for website solutions. We create and develop websites that are assured to upscale your business.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">E-Commerce Application Development
                  </h4>
                  <hr>
                  <p class="card-text"><b>The rapidly booming user base on the internet has opened doors to many methods
                    of doing shopping and transactions, one of them being the highly recognized and beneficial
                    E-commerce.</b></p>
                  <p class="card-text text-justify">For every single business, be it the entrepreneurs working from home or large
                    corporations, e-commerce surely complements their business in a skyrocketing pattern. The fact that
                    it provides much less time-consuming methods is something that can’t be left unnoticed.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Online Reputation Management
                  </h4>
                  <hr>
                  <p class="card-text"><b>Online reputation management simply means having a system in place that serves
                    to produce, cultivate and flourish your online brand name. Creating a digital public image in a way
                    so that it portrays the best reviews.</b></p>
                  <p class="card-text text-justify">providing an optimistic customer experience to the viewers by deploying ideas to
                    create, improvise and respond to the reviews that are placed online. ORM also makes sure to throw
                    attention to all the famous review sites.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Who we are section -->

    <div class="mt-4 mb-4 ">
      <div class="choose-us-section pt-4 pb-4 header_margin">
        <div class="ml-md-5 mr-md-5 ">
          <h4 class="mt-2 choose-us-header">Who We Are</h4>
          <hr>
          <p class="mt-4" style="font-size: 0.92em; color: gray; font-family: 'Poppins-Regular', sans-serif">We are a
            company that is recognized both nationally as well as internationally in the software, mobile, and web
            application development province. The one thing that we can assure you of is delivering accurate and
            meticulous data as we understand how important this is to you and your business. </p>
          <p>We render our best shot to harness the perfect output. Our team has undergone thorough hands-on experience
            with emerging technologies and expert training to assist our clients in their success stories.</p>
        </div>
      </div>
    </div>


    <!-- Top clients -->

    <div class="our-clients mt-4 mb-4 header_margin">
      <div class="our-clients-title ">
        <h4 class="mt-2 choose-us-header">Our Top Clients</h4>
        <hr>
      </div>

      <div class="our-clients-body my-5">
        <swiper class="swiper home-slider" :options="swiperOption">
          <swiper-slide>
            <div class="our-clients-img"><img alt="#" src="../src/assets/img/web/clients/5.png"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="our-clients-img"><img alt="#" src="../src/assets/img/web/clients/1.png"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="our-clients-img"><img alt="#" src="../src/assets/img/web/clients/3.png"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="our-clients-img"><img alt="#" src="../src/assets/img/web/clients/2.png"></div>
          </swiper-slide>

          <swiper-slide>
            <div class="our-clients-img"><img alt="#" src="../src/assets/img/web/clients/4.png"></div>
          </swiper-slide>


        </swiper>
      </div>
    </div>

    <!--choose-us-section -->

    <div class="choose-us-section-bg mt-4 mb-4 ">
      <div class="choose-us-section pt-4 pb-4 header_margin">
        <div class="ml-md-5 mr-md-5 ">
          <h4 class="mt-2 choose-us-header">Why should you choose us ?</h4>
          <hr>
          <p class="mt-4">Being the best Information Technology Company in Kerala, we ensure our clients get the best
            solutions for their business that meet beyond their expectations.</p>
        </div>
        <div class="row m-0">
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/1.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">One-stop-shop</h4>
                <p class="card-text">For all your software and applications needs, stay rest assured and rely
                  confidently on Xeoscript Technologies. </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/2.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">Tailored to your needs</h4>
                <p class="card-text">Our best team of developers ensures that you received carved out-of-the-box
                  applications and powerful customized solutions.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/3.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">Stalwarts of experts</h4>
                <p class="card-text">We have a team of specialists who work with perfection and passion to develop
                  products that suit our clients business.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/4.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">Value of money</h4>
                <p class="card-text">We don't want our clients to feel burdened with hefty prices. We also consider our
                  client’s budget constraints before quoting a price.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/5.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">Client-centric</h4>
                <p class="card-text">One of the main factors that attribute to our reputation is our “client comes
                  first” attitude.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card ">
              <img class="card-img-top" src="../src/assets/img/web/others/6.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title">Global exposures</h4>
                <p class="card-text">Having worked with numerous clients from across the globe such as the USA, UK,
                  Australia, Canada, etc, we are proud of our huge international exposure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Footer section -->

    <div class="footer ">
      <div class="header_margin pt-4 pb-4">
        <div class="row m-0 ml-4 mr-4">
          <div class="contact-details col-md-5 col-sm-12 p-0">
            <h5>CONTACT DETAILS</h5>
            <p>We are always happy to hear from you and there are various ways to contact us</p>
            <div class="address d-flex align-baseline">
              <img alt="#" src="../src/assets/img/web/footer/address.png">
              <div class="pl-4">
                <p>Xeoscript Technologies</p>
                <p>First Floor, Kalarikkal Building</p>
                <p>Mannuthy By-Pass, Mannuthy P O,</p>
                <p>Thrissur, Kerala, India - 680651</p>
                <p></p>
              </div>
            </div>
            <div class="d-xl-flex contact-number">
              <p>
                <a class="text-decoration-none text-white" href="tel:+917994000666">
                  <img class="mr-4 mr-xl-0 img-fluid" alt="#" src="../src/assets/img/web/footer/call.png"> +91 7994
                  000
                  666
                </a>
              </p>
              <p class="pl-xl-4">
                <a class="text-decoration-none text-white" href="mailto:info@xeoscript.com" target="_blank">
                  <img class="mr-4 mr-xl-0 img-fluid" alt="#" src="../src/assets/img/web/footer/mail.png">
                  info@xeoscript.com
                </a></p>
            </div>
          </div>
          <div class="map-container col-md-5   offset-md-1 col-sm-12 p-0 w-100">
            <div id="map" style="display: none" class="embed-responsive embed-responsive-16by9"></div>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe width="100%" height="100%" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=xeoscript%20technologies%20mannuthy&t=&z=11&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                <a href="https://putlocker-is.org"></a><br>
              </div>
            </div>
            <div class="list  footer_social ">
              <ul class="d-flex m-0 justify-content-center">
                <li>
                  <a href="https://www.facebook.com/xeoscript" target="_blank">
                    <img alt="#" src="../src/assets/img/web/socialmedia/fb.png">
                  </a>
                </li>
                <li>
                  <a href=" https://www.instagram.com/xeoscript/" target="_blank">
                    <img alt="#" src="../src/assets/img/web/socialmedia/insta.png">
                  </a>
                </li>
                <li class="d-none">
                  <a href="#">
                    <img alt="#" src="../src/assets/img/web/socialmedia/twitt.png">
                  </a>
                </li>
                <li class="d-none">
                  <a href="#">
                    <img alt="#" src="../src/assets/img/web/socialmedia/pin.png">
                  </a>
                </li>
                <li class="d-none">
                  <a href="">
                    <img alt="#" src="../src/assets/img/web/socialmedia/youtb.png">
                  </a>
                </li>
                <li class="d-none">
                  <a href="">
                    <img alt="#" src="../src/assets/img/web/socialmedia/behans.png">
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/xeoscript-technologies/" target="_blank">
                    <img alt="#" src="../src/assets/img/web/socialmedia/linked.png">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <a id="stop" class="scrollTop" v-scroll-to="'#top'" href="#">
          <span>
            <i class="fa fa-angle-double-up"></i>
          </span>
      </a>


      <div class="pt-3 pb-3 border-top text-center copyright">

        <p><i class="fa fa-copyright"></i> <a class="" href="https://xeoscript.com/" target="_blank">Xeoscript
          Technologies</a> {{ new Date().getFullYear() }} All rights reserved</p>

      </div>


    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import axios from 'axios';


export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      state: 'D',
      name: '',
      email: '',
      phone: '',
      checkedService: [],
      message: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: false,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        // autoplay: false,
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      },
      swiperOption1: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: false,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        // autoplay: false,
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      }
    }

  },

  methods: {
    onSubmit() {
      const emailValue = this.valthisemaIL();
      if (!emailValue) {
        return;
      }

      const checked = this.valthisform();
      if (!checked) {
        return;
      }

      console.log('Here....')
      const bodyFormData = new FormData();
      bodyFormData.append('name', this.name);
      bodyFormData.append('phone', this.phone);
      bodyFormData.append('email', this.email);
      bodyFormData.append('checkedService', this.checkedService);
      bodyFormData.append('message', this.message)

      const that = this;

      this.state = 'L';

      axios({
        method: "post",
        url: "https://webdevelopment.xeoscript.com/mail.php",
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(function (response) {
        that.onSubmitSuccess(response);
      }).catch(function (response) {
        that.onSubmitFailed(response);
      });
    },
    onSubmitSuccess() {
      this.state = 'S';
    },

    onSubmitFailed() {
      this.state = 'F';
    },

    onlyNumberKey(evt) {
      evt = (evt) || window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    isEmailValid: function () {
      return (this.email === "") ? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
    },

    valthisemaIL() {
      const element = this.$el.querySelector("#email");
      if (element.classList.contains('has-error')) {
        alert("Please enter a valid the email");
        return false;
      } else {
        return true;
      }
    },

    valthisform() {
      const chkd = document.FC.c1.checked || document.FC.c2.checked || document.FC.c3.checked || document.FC.c4.checked || document.FC.c5.checked || document.FC.c6.checked;
      if (chkd === false) {
        alert("Please select a service required");
        return false;
      } else {
        return true;
      }
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z ]*$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

  }
}
</script>

<style scoped>
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 80%;
  width: 100%;
}


</style>