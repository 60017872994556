import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import {LayoutPlugin} from 'bootstrap-vue'

Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import {ModalPlugin} from 'bootstrap-vue'

Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import {CardPlugin} from 'bootstrap-vue'

Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import {VBScrollspyPlugin} from 'bootstrap-vue'

Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import {DropdownPlugin, TablePlugin} from 'bootstrap-vue'

Vue.use(DropdownPlugin)
Vue.use(TablePlugin)

import {CarouselPlugin} from 'bootstrap-vue'

Vue.use(CarouselPlugin)


import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)

import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo)

import SimpleVueValidation from 'simple-vue-validator';

Vue.use(SimpleVueValidation)

import './assets/css/fonts.css'
import './assets/css/font-awesome.css'
import './assets/css/custom.css'


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
